import React from 'react'
import { values } from 'lodash'
import { useTranslation } from 'react-i18next'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { makeStyles } from '@material-ui/core/styles'
import { OFFER_CANCEL_REASONS, REQUEST_CANCEL_REASONS, OFFER_TYPES } from '../../constants/offers'


const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: theme.spacing(2)
    }
}))

const CancelReason = ({ type, onChange, value }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const options = type === OFFER_TYPES.OFFER ? OFFER_CANCEL_REASONS : REQUEST_CANCEL_REASONS
    return (
        <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">{t('cancel_reason')}</FormLabel>
            <RadioGroup name="cancel-reason" value={value} onChange={(e) => onChange(e.target.value)}>
                {
                    values(options).map(v => (
                        <FormControlLabel value={v} key={v} control={<Radio />} label={t(v)} />
                    ))
                }

            </RadioGroup>
        </FormControl>
    )
}

export default CancelReason
