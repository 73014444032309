import firebase from 'firebase'
import 'firebase/firestore'

export const config = {
  apiKey: 'AIzaSyAAk-J7mXx8uOCgfQnd7gIwO5rW9mFodNo',
  authDomain: 'mint-dev-532af.firebaseapp.com',
  databaseURL: 'https://mint-dev-532af.firebaseio.com',
  projectId: 'mint-dev-532af',
  storageBucket: 'mint-dev-532af.appspot.com',
  messagingSenderId: '190771214781',
  appId: '1:190771214781:web:911ac643ece3b2ae303ad4',
  measurementId: 'G-4FRM2VFKNG',
  region: 'europe-west1',
  amalfiApplicationId: '1c7b1603-1662-44a2-8f59-d0d82882b3d8',
  amalfiTenant: 'c9520924-d349-4ca7-b5e9-d2956e0812f4',
  amalfiLoginKey: 'qn_swhO7AwxD62lH9vB92PVlwwtj0nt17jGkxjr2LRg',
  loginUrl: 'https://login.mint.amalfianalytics.com/login',
  refreshUrl: 'https://login.mint.amalfianalytics.com/refresh'
}
const shouldUseEmulator = window.location.hostname === 'localhost' // or other logic to determine when to use
// Emulate RTDB
if (shouldUseEmulator) {
  config.databaseURL = `http://localhost:9000?ns=${config.projectId}`
  console.debug(`Using RTDB emulator: ${config.databaseURL}`)
}

const firebaseApp = firebase.initializeApp(config)
// if ((window.Cypress) || (window.location.host.startsWith('localhost') && process.env.REACT_APP_EMULATOR === 'true')) {
//   firebaseApp.firestore().settings({ host: 'localhost:8080', ssl: false, experimentalForceLongPolling: true })
//   firebase.functions().useFunctionsEmulator('http://localhost:5001')
// }
const firestoreSettings = {}
// Pass long polling setting to Firestore when running in Cypress
if (window.Cypress) {
  // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
  firestoreSettings.experimentalForceLongPolling = true
}

// Emulate Firestore
if (shouldUseEmulator) {
  firestoreSettings.host = 'localhost:8080'
  firestoreSettings.ssl = false
  console.debug(`Using Firestore emulator: ${firestoreSettings.host}`)

  firebase.firestore().settings(firestoreSettings)
}

export default firebaseApp
